// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-analytics-js": () => import("./../src/pages/analytics.js" /* webpackChunkName: "component---src-pages-analytics-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-explorer-js": () => import("./../src/pages/explorer.js" /* webpackChunkName: "component---src-pages-explorer-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-get-the-data-js": () => import("./../src/pages/get-the-data.js" /* webpackChunkName: "component---src-pages-get-the-data-js" */),
  "component---src-pages-in-action-dallas-isd-js": () => import("./../src/pages/in-action/dallas-isd.js" /* webpackChunkName: "component---src-pages-in-action-dallas-isd-js" */),
  "component---src-pages-in-action-index-js": () => import("./../src/pages/in-action/index.js" /* webpackChunkName: "component---src-pages-in-action-index-js" */),
  "component---src-pages-in-action-operation-connectivity-js": () => import("./../src/pages/in-action/operation-connectivity.js" /* webpackChunkName: "component---src-pages-in-action-operation-connectivity-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-signup-js": () => import("./../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-school-template-js": () => import("./../src/templates/schoolTemplate.js" /* webpackChunkName: "component---src-templates-school-template-js" */)
}

